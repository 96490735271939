<template>
  <div v-loading="isLoading" class="detail ">
    <div class="max-content">
      <div v-if="detail" class="detail">
        <div class="banner">
          <detail-banner :banners="detail.new_images"></detail-banner>
        </div>
        <div class="detail-content dfr mdfc cross-start main-between">
          <div class="content-left">
            <!-- full_name -->
            <div class="popular-title fs32 fc000 fb">
              {{ detail.full_name }}
            </div>
            <!-- address -->
            <div class="address-info dfr cross-center fs14 fc333">
              <div class="address-icon">
                <img src="../assets/images/address.png" class="w100 " alt="" />
              </div>
              <div class="address">
                <span>{{ detail.address }}</span>
                <!-- <span v-if="detail.street">,</span>
                <span v-if="detail.street">{{ detail.street }}</span> -->
              </div>
            </div>
            <!--  tag-->
            <div class="tags">
              <div
                v-for="(item, index) in detail.tags"
                :key="index"
                class="tag"
              >
                {{ item }}
              </div>
            </div>

            <!-- reason -->
            <div class="popular-recommend fs18 fc333">
              {{ detail.describe }}
            </div>
            <!-- rooms -->
            <div class="rooms-wrap fs14 fc666">
              <div class="rooms-box dfr cross-center ">
                <div
                  class="room-f room-inner-count dfc cross-center main-between"
                >
                  <div class="room-top dfr cross-cneter ">
                    <!-- price -->
                    <div class="price-box fs16 fc333">
                      ${{ detail.floor_price | formatPrice }} - ${{
                        detail.top_price | formatPrice
                      }}
                    </div>
                  </div>
                  <div class="fs14 fc333 fw500 room-desc">
                    {{ langText.price }}
                  </div>
                </div>
                <div
                  class="room-s room-inner-count dfc cross-center main-between"
                >
                  <div class="room-top dfr cross-cneter">
                    <div class="room-icon">
                      <img
                        src="../assets/images/bedroom.png"
                        class="w100"
                        alt=""
                      />
                    </div>

                    <div class="fs16 fc333 fw500">{{ detail.bedrooms }}</div>
                  </div>
                  <div class="fs14 fc333 fw500 room-desc">
                    {{ langText.bedrooms }}
                  </div>
                </div>
                <div
                  class="room-t room-inner-count dfc cross-center main-between"
                >
                  <div class="room-top dfr cross-cneter">
                    <div class="room-icon">
                      <img
                        src="../assets/images/bath.png"
                        class="w100"
                        alt=""
                      />
                    </div>

                    <div class="fs16 fc333 fw500">{{ detail.bath }}</div>
                  </div>
                  <div class="fs14 fc333 fw500 room-desc">
                    {{ langText.bath }}
                  </div>
                </div>
              </div>
            </div>
            <!-- tabs -->
            <div class="tabs-wrap fs22">
              <el-tabs v-model="activeName" @tab-click="handleTabClick">
                <el-tab-pane :label="langText.floorPlan" name="floorplan">
                  <detail-plan :lang="lang" :detail="detail"></detail-plan>
                </el-tab-pane>
                <el-tab-pane :label="langText.amenity" name="amenity">
                  <detail-amenity :detail="detail"></detail-amenity>
                </el-tab-pane>
                <el-tab-pane :label="langText.surrounding" name="surrounding">
                  <detail-surrounding
                    :lang="lang"
                    :detail="detail"
                  ></detail-surrounding>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <!-- Apply Send Message -->
          <div class="content-right">
            <!-- is_send 1已发送 2未发送 -->

            <div v-if="detail.is_send == 1" class="send-apply-end">
              <div class="referral fs18  fw500 fc000">
                {{ langText.referral.title }} :
                <span class="fctheme">{{ detail.code || "no code" }}</span>
              </div>
              <div class="wechat fs18 fw500  fc000">
                {{ langText.referral.wechat }} :
                <span class="fctheme">{{
                  detail.service_wechat || "no wechat"
                }}</span>
              </div>
              <div v-if="detail.url" class="wechat fs18 fw500  fc000">
                {{ langText.referral.url }} :
                <a target="_blank" :href="detail.url" class="link fctheme">{{
                  langText.referral.url
                }}</a>
              </div>
              <div class="desc fs16 fc666 w100">
                {{ langText.referral.desc }}
              </div>
            </div>
            <div v-else class="send-apply">
              <div class="send-message-box fs14 fc666">
                <div class="send-cell-wrap">
                  <div class="cell-title fs14 fc666">
                    {{ langText.applySendMessage.roomType }}
                  </div>
                  <div class="input-box">
                    <el-select
                      class="filter-item w100"
                      v-model="currentTyps"
                      :disabled="!isLogin"
                      :placeholder="langText.beds"
                    >
                      <el-option
                        v-for="item in detail.beds"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <!-- <div class="send-cell-wrap">
                  <div class="cell-title fs14 fc666">
                    {{ langText.applySendMessage.emailAddress }}
                  </div>
                  <div class="input-box">
                    <el-input
                      :class="[emailFormatErr ? 'show-err' : '']"
                      v-model.trim="email"
                      @input="emailChange"
                      :placeholder="langText.applySendMessage.emailAddress"
                    ></el-input>
                  </div>
                </div> -->
                <!-- <div v-if="emailFormatErr" class="emailFormatErr">
                  Please enter the correct email format
                </div> -->
                <div class="send-cell-wrap">
                  <div class="cell-title fs14 fc666">
                    {{ langText.applySendMessage.moveInDate }}
                  </div>
                  <div class="input-box detail-date-wrap">
                    <el-date-picker
                      :disabled="!isLogin"
                      prefix-icon=""
                      class="filter-item w100"
                      v-model="moveDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      :placeholder="langText.applySendMessage.moveInDate"
                    >
                    </el-date-picker>
                  </div>
                </div>
                <div
                  v-if="isLogin"
                  @click="sendMessage"
                  class="w100 btn-send pointer dfr flex-center fs16 fcfff"
                >
                  {{ langText.applySendMessage.sendMessage }}
                </div>
                <div
                  v-else
                  @click="showLogin"
                  class="btn-send text-center pointer dfr flex-center fs16 fcfff"
                >
                  {{ langText.applySendMessage.loginText }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty dfc flex-center"></div>
    </div>
  </div>
</template>

<script>
import { toZhDigit } from "../utils/util";
export default {
  data() {
    return {
      isLoading: true,
      id: "",
      detail: null,
      activeName: "floorplan",
      firstname: "",
      lastname: "",
      email: "",
      moveDate: "",
      currentTyps: "",
      emailFormatErr: false,
      serviceTel: "",
      langText: {
        floorPlan: "Floor Plan",
        amenity: "Amenity",
        surrounding: "Surrounding",
        applySendMessage: {
          title: "Apply Send Message",
          tel: "tel",
          firstName: "First Name *",
          lastName: "Last Name *",
          roomType: "Room Type *",
          emailAddress: "Email Address *",
          moveInDate: "Move-in Date *",
          sendMessage: "Send Message"
        },
        referral: {
          title: "Referral Code",
          wechat: "WeChat",
          desc:
            "If you have any questions, please add customer service WeChat for consultation"
        }
      },
      lang: "EN",
      isLogin: false
    };
  },
  async created() {
    this.id = parseFloat(this.$route.query.id) || "";
  },
  async mounted() {
    this.$nextTick(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
    this.initLang();
    this.$bus.$on("changeLang", async () => {
      await this.initLang();
      if (this.$route.name == "detail") {
        this.targetByLang();
      }
    });
    this.$bus.$on("updateInfo", () => {
      this.initLogin();
    });
    this.$bus.$on("logout", () => {
      this.isLogin = false;
    });
    this.initLogin();
    await this.initPage();
    this.isLoading = false;
  },
  methods: {
    initLogin() {
      // TODO
      let isLogin = this.$localStore.get("isLogin");
      this.isLogin = isLogin;
    },
    async initLang() {
      let lang = await this.$localStore.get("lang");
      if (lang == "EN") {
        this.langText = this.$EN.detail;
      } else if (lang == "CN") {
        this.langText = this.$CN.detail;
      }
      this.lang = lang;
    },
    async initPage() {
      await this.initDetail();
      this.initTel();
      this.initSurrounding();
    },
    async initTel() {
      const resp = await this.$API
        .post("sendTel", "", false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.serviceTel = resp.data.service[0].tel;
      }
    },
    // tag:   1中文 2英文
    async initDetail() {
      let userId = this.$localStore.get("userId") || "";

      if (!this.id) {
        this.$message("暂无数据");
      }
      const resp = await this.$API
        .post("detail", { id: this.id, uid: userId }, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        let info = resp.data;
        if (info.max_rooms == info.min_rooms) {
          info.roomsNum = parseFloat(info.max_rooms);
        } else {
          info.roomsNum = `${parseFloat(info.min_rooms)} - ${parseFloat(
            info.max_rooms
          )}`;
        }
        if (info.max_bedrooms == info.min_bedrooms) {
          info.bedrooms = parseFloat(info.max_bedrooms);
        } else {
          info.bedrooms = `${parseFloat(info.min_bedrooms)} - ${parseFloat(
            info.max_bedrooms
          )}`;
        }
        if (info.max_bath == info.min_bath) {
          info.bath = parseFloat(info.max_bath);
        } else {
          info.bath = `${parseFloat(info.min_bath)} - ${parseFloat(
            info.max_bath
          )}`;
        }
        if (info.rooms) {
          let beds = [];
          for (let key in info.rooms) {
            let _data = { label: ` ${key} BED`, value: key };
            if (this.lang == "CN") {
              _data = { label: ` ${toZhDigit(key)}人间`, value: key };
            }
            beds.push(_data);
          }

          if (beds[0] && beds[0].value == 1) {
            if (this.lang == "CN") {
              beds[0].label = "单人间";
            } else {
              beds[0].label = "Studio";
            }
          }

          info.beds = beds;
        }
        if (info.around_school.length) {
          info.around_school.map(item => {
            item.drive = item.drive + "min";
            item.distance = item.distance + "mi";
            return item;
          });
        }
        // tag:   1中文 2英文

        this.detail = info;
        this.targetByLang();
      }
    },
    targetByLang() {
      if (
        (this.lang == "CN" && this.detail.tag != 1) ||
        (this.lang == "EN" && this.detail.tag != 2)
      ) {
        this.$goReplace("detail", { id: this.detail.relevancy_id });
      }
    },
    handleTabClick(e) {
      this.activeName = e.name;
    },
    firstnameChange() {},
    lastnameChange() {},
    emailChange() {
      if (this.email && this.validateEmail(this.email)) {
        this.emailFormatErr = false;
      }
    },
    validateEmail(email) {
      // 邮箱验证正则
      var reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      return reg.test(email);
    },
    async sendMessage() {
      if (!this.detail) {
        this.$message("no apartment info");
        return;
      }
      // if (!this.firstname) {
      //   this.$message("Please fill in the first name");
      //   return;
      // }
      // if (!this.lastname) {
      //   this.$message("Please fill in the last name");
      //   return;
      // }
      if (!this.currentTyps) {
        this.$message("Please select a room type");
        return;
      }
      // if (!this.email) {
      //   this.$message("Please fill in the email");
      //   return;
      // }
      // if (this.email && !this.validateEmail(this.email)) {
      //   this.emailFormatErr = true;
      //   return;
      // }
      if (!this.moveDate) {
        this.$message("Please fill in the date of removal");
        return;
      }
      const data = {
        apartment_id: this.detail.id,
        // username: this.firstname + this.lastname,
        room_type: this.currentTyps,
        // email: this.email,
        move_date: this.moveDate
      };

      const resp = await this.$API.post("sendMessage", data).then(resp => resp);
      if (resp.code == 1000) {
        this.firstname = "";
        this.lastname = "";
        this.currentTyps = "";
        this.email = "";
        this.moveDate = "";
        this.$message({
          message: "Application is successful",
          type: "success"
        });
        this.initPage();
      }
    },
    showLogin() {
      this.$bus.$emit("showLogin", "sign");
    },
    async initSurrounding() {
      //
      // const resp = await this.$API.axios
      //   .get("https://admin.allsethomeus.com/api/Home/surroundings", {
      //     params: {
      //       origin: "北京",
      //       destination: "上海",
      //       key: this.$config.mapKey
      //     },
      //     dataType: "jsonp",
      //     cache: false
      //   })
      //   .then(resp => resp);
      //
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.popular-recommend {
  margin: 40px auto 0;
}
.detail {
  min-height: calc(100vh - 80px - 446px);
}
.link {
  text-decoration: underline;
}
.empty {
  height: 200px;
}
.emailFormatErr {
  color: #fb7373;
  margin-top: 8px;
}
.wechat {
  margin: 20px 0 20px;
}
.detail-content {
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0 100px;
  max-width: 90%;
  box-sizing: border-box;
  .content-left {
    width: 800px;
    .address-info {
      margin: 10px 0 10px;
      .address-icon {
        width: 12px;
        margin-right: 6px;
      }
    }

    .tags {
      margin: 0 0 20px;
      .tag {
        display: inline-block;
        padding: 8px 16px;
        background: #f4f4f4;
        border-radius: 30px;
        color: #484848;
        font-size: 16px;
        margin: 14px 10px 0 0;
        &:first-child {
          background: #e2fae9;
          color: #02902c;
        }
      }
    }
    .rooms-wrap {
      width: 800px;
      .rooms-box {
        width: 100%;
        margin: 30px auto 0;
        padding-top: 30px;
        border-top: 1px solid #eee;
        .room-inner-count {
          margin-right: 71px;
        }

        .room-top {
          margin-bottom: 14px;
          // transition: all 0.3s;
        }
        .room-icon {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }
    }
    .tabs-wrap {
      margin: 50px 0 0;
    }
  }
  .content-right {
    width: 360px;

    background: #ffffff;
    border: 1px solid #e4e4e4;
    padding: 24px 24px 40px;
    box-sizing: border-box;
    .send-apply {
      // min-height: 400px;
      box-sizing: border-box;
    }
    .send-apply-end {
      width: 100%;
    }
    .desc {
      margin: 9px 0 0;
    }
    .send-message-box {
      .name-wrap {
        margin-top: 10px;
        .name-box {
          width: 150px;
          .input-box {
            margin-top: 8px;
          }
        }
      }
      .send-cell-wrap {
        margin-top: 14px;
        .input-box {
          margin-top: 8px;
          width: 100%;
        }
      }
      .btn-send {
        width: 100%;
        height: 42px;
        background: #87bcdc;
        border-radius: 4px;
        transition: all 0.3s;
        opacity: 0.9;
        margin-top: 30px;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .detail-content {
    width: 90vw;
    .content-left {
      width: 100%;
      .rooms-wrap {
        width: 100%;
        .rooms-box {
          .room-inner-count {
            margin-right: 40px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .content-right {
      width: 100%;
      margin-top: 60px;
    }
  }
}
</style>
